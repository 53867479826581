import { LIVE_PRICING, PAGE_TYPE } from 'Constants/constants';

export const checkIsLivePricingEnabled = (
	pageType: string,
	livePricingSupported: string | undefined,
) => {
	switch (pageType) {
		case PAGE_TYPE.BOOKING:
			return livePricingSupported === LIVE_PRICING.SUPPORTED;

		case PAGE_TYPE.CHECKOUT:
			return (
				livePricingSupported === LIVE_PRICING.SUPPORTED ||
				livePricingSupported === LIVE_PRICING.CHECKOUT_ONLY
			);

		default:
			return false;
	}
};
