import cookies from 'nookies';

import { notify } from 'Components/common/notify';

import { validateLiveInventoryFromCache } from 'Utils/bookingFlowUtils';
import {
	getApiCurrencyParameter,
	getBaseRequestOptions,
} from 'Utils/fetchUtils';
import fetch from 'Utils/fetchWrapper';
import { checkIsLivePricingEnabled } from 'Utils/livePricingUtils';
import { error } from 'Utils/logUtils';
import {
	getCurrentCurrency,
	getCurrentPage,
	getProduct,
} from 'Utils/stateUtils';
import { getApiBaseUrl, getApiCDNBaseUrl } from 'Utils/urlUtils';

import {
	receiveCalendarInventories,
	requestCalendarInventories,
} from 'Actions/calendar';
import { setAPIServerAPIStatus } from 'Actions/serverStatus';

import { PAGE_TYPE } from 'Constants/constants';

export const fetchCalendarInventories =
	({
		id,
		fromDate = '',
		toDate = '',
		currency,
		req,
		tourId,
		variantId,
		shouldFetchLivePrice,
		shouldFetchLiveInventory,
	}: any) =>
	(dispatch: any, getState: any) => {
		const currentCurrency = getCurrentCurrency(getState()) || currency;
		const currentCurrencyParam = getApiCurrencyParameter(
			getState(),
			currentCurrency,
		);
		const product = getProduct(getState(), id);
		const { livePricingSupported, liveInventoryCheck } = product ?? {};
		const isLivePricingEnabled = checkIsLivePricingEnabled(
			PAGE_TYPE.BOOKING,
			livePricingSupported,
		);

		let forceFetchInventoryFromDB = false;

		if (
			typeof window !== 'undefined' &&
			!shouldFetchLiveInventory &&
			liveInventoryCheck
		) {
			const Cookies = cookies.get();
			forceFetchInventoryFromDB = validateLiveInventoryFromCache(
				Cookies,
				`${id}`,
			);
		}

		const isBookingPage = getCurrentPage(getState()) === PAGE_TYPE.BOOKING;
		const fromDateParam = fromDate ? `&from-date=${fromDate}` : '';
		const toDateParam = toDate ? `&to-date=${toDate}` : '';
		const tourIdParam = tourId ? `&tourId=${tourId}` : '';
		const variantIdParam = variantId ? `&variantId=${variantId}` : '';
		const livePriceParam =
			isBookingPage && isLivePricingEnabled && shouldFetchLivePrice // shouldFetchLivePrice flag is only on for Fn calls from Next server functions (isServer isn't sufficient since we'd want this to work on CSR)
				? '&livePricingSupported=true'
				: '';
		const hostName =
			isLivePricingEnabled ||
			shouldFetchLiveInventory ||
			forceFetchInventoryFromDB
				? getApiBaseUrl()
				: getApiCDNBaseUrl({
						state: getState(),
				  });

		const url = `${hostName}/api/v7/tour-groups/${id}/calendar/?${fromDateParam}${toDateParam}${currentCurrencyParam}${tourIdParam}${variantIdParam}${livePriceParam}`;

		dispatch(requestCalendarInventories({ id, tourId }));

		const baseOptions = req
			? { headers: { cookie: req.headers.cookie } }
			: {};
		const options = getBaseRequestOptions(getState(), baseOptions);

		return fetch(url, options)
			.then(response => response.json())
			.then(inventoryJson =>
				dispatch(
					receiveCalendarInventories({
						id,
						tourId,
						payload: {
							...inventoryJson,
						},
					}),
				),
			)
			.catch(err => {
				dispatch(setAPIServerAPIStatus(url, err.status));
				error(err);
				notify.showNetworkError(err);
			});
	};
