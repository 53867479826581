import { notify } from 'Components/common/notify';

import fetch from 'Utils/fetchWrapper';
import { error } from 'Utils/logUtils';
import {
	getCurrentLanguageCode,
	getFetchPoiInfoBaseApiUrl,
} from 'Utils/stateUtils';
import { addQueryParamsToUrl, getApiCDNBaseUrlV2 } from 'Utils/urlUtils';

import { decrementAPICount } from 'Actions/apiCount';
import {
	receiveBatchPOIInfoById,
	receivePOIInfoByCollectionId,
	receivePOIInfoById,
	receivePOIInfoByTgId,
	requestBatchPOIInfoById,
	requestPOIInfoByCollectionId,
	requestPOIInfoById,
	requestPOIInfoByTgId,
} from 'Actions/poi';
import { setAPIServerAPIStatus } from 'Actions/serverStatus';
import type { TBatchPOIThunkParams, TPOIThunkParams } from 'ReduxTypes/poi';

export const fetchPoiInfo =
	({
		poiId,
		tourgroupId,
		collectionId,
		content = false,
		location = false,
		operatingSchedules = false,
	}: TPOIThunkParams) =>
	(dispatch: any, getState: any) => {
		const state = getState();
		const lang = getCurrentLanguageCode(state).toUpperCase();

		const url = getFetchPoiInfoBaseApiUrl(state, {
			poiId,
			tourgroupId,
			collectionId,
			content,
			location,
			operatingSchedules,
			language: lang,
		});

		switch (true) {
			case !!tourgroupId:
				dispatch(
					requestPOIInfoByTgId({
						tgid: tourgroupId!,
						content,
						location,
						operatingSchedules,
						language: lang,
					}),
				);
				break;
			case !!collectionId:
				dispatch(
					requestPOIInfoByCollectionId({
						collectionId: collectionId!,
						content,
						location,
						operatingSchedules,
						language: lang,
					}),
				);
				break;
			default:
				dispatch(
					requestPOIInfoById({
						id: poiId!,
						content,
						location,
						operatingSchedules,
						language: lang,
					}),
				);
				break;
		}

		return fetch(url)
			.then(response => response.json())
			.then(json => {
				switch (true) {
					case !!tourgroupId:
						dispatch(
							receivePOIInfoByTgId({
								tgid: tourgroupId!,
								content,
								location,
								operatingSchedules,
								language: lang,
								response: json,
							}),
						);
						break;
					case !!collectionId:
						dispatch(
							receivePOIInfoByCollectionId({
								collectionId: collectionId!,
								content,
								location,
								operatingSchedules,
								language: lang,
								response: json,
							}),
						);
						break;
					default:
						dispatch(
							receivePOIInfoById({
								id: poiId!,
								content,
								location,
								operatingSchedules,
								language: lang,
								response: json,
							}),
						);
						break;
				}
			})
			.catch(err => {
				dispatch(setAPIServerAPIStatus(url, err.status));
				error(err);
				notify.showNetworkError(err);
				dispatch(decrementAPICount());
			});
	};

export const batchFetchPOIInfo =
	({
		poiIds,
		content = false,
		location = false,
		operatingSchedules = false,
	}: TBatchPOIThunkParams) =>
	(dispatch: any, getState: any) => {
		const state = getState();
		const lang = getCurrentLanguageCode(state).toUpperCase();
		const urlBase = `${getApiCDNBaseUrlV2({
			state,
		})}/api/v1/pois`;
		const url = addQueryParamsToUrl(urlBase, {
			'ids[]': poiIds,
			content,
			location,
			operatingSchedules,
			language: lang,
		})!;

		dispatch(
			requestBatchPOIInfoById({
				ids: poiIds,
				language: lang,
				content,
				location,
				operatingSchedules,
			}),
		);

		return fetch(url)
			.then(response => response.json())
			.then(json => {
				dispatch(
					receiveBatchPOIInfoById({
						ids: poiIds,
						language: lang,
						content,
						location,
						operatingSchedules,
						response: json,
					}),
				);
			})
			.catch(err => {
				dispatch(setAPIServerAPIStatus(url, err.status));
				error(err);
				notify.showNetworkError(err);
				dispatch(decrementAPICount());
			});
	};
