import { sortDateArray } from 'Utils/dateUtils';

import { ActionTypes } from 'Actions/actions';
import { CalendarActionTypes } from 'ReduxTypes/calendar';

export const requestCalendarInventories = ({
	id,
	tourId,
}: any): CalendarActionTypes => ({
	type: ActionTypes.REQUEST_CALENDAR_INVENTORIES,
	payload: {
		id,
		tourId,
	},
});

export const receiveCalendarInventories = ({
	id,
	tourId,
	payload,
}: any): CalendarActionTypes => {
	const { dates: inventoryMap, metadata } = payload;
	const sortedInventoryDates = sortDateArray(Object.keys(inventoryMap));
	const { startDate: fromDate, endDate: toDate, currency } = metadata;
	return {
		type: ActionTypes.RECEIVE_CALENDAR_INVENTORIES,
		payload: {
			id,
			inventoryMap,
			sortedInventoryDates,
			fromDate,
			toDate,
			currency,
			tourId,
		},
	};
};
