import { notify } from 'Components/common/notify';

import {
	getApiCurrencyParameter,
	getApiLanguageParameter,
	getBaseRequestOptions,
} from 'Utils/fetchUtils';
import fetch from 'Utils/fetchWrapper';
import { error } from 'Utils/logUtils';
import PlatformUtils from 'Utils/platformUtils';
import { getCurrentCurrency } from 'Utils/stateUtils';
import { getApiCDNBaseUrlV2 } from 'Utils/urlUtils';

import {
	receiveSimilarProducts,
	requestSimilarProducts,
} from 'Actions/product';

const commonFetchSimilarProducts =
	// @ts-expect-error TS(7031): Binding element 'id' implicitly has an 'any' type.


		({ id, count = 6, lang = 'en', req }) =>
		(dispatch: any, getState: any) => {
			dispatch(requestSimilarProducts({ id }));
			const currencyCode = getCurrentCurrency(getState());
			const currencyParam = getApiCurrencyParameter(
				getState(),
				currencyCode,
			);
			const langParam = getApiLanguageParameter(lang);
			const url = `${getApiCDNBaseUrlV2({
				state: getState(),
			})}/api/v6/tour-groups/${id}/similar-products?limit=${count}${langParam}${currencyParam}`;
			const requestOptions = req
				? { headers: { cookie: req.headers.cookie } }
				: {};
			const options = getBaseRequestOptions(getState(), requestOptions);

			return fetch(url, options)
				.then(response => response.json())
				.then(json => {
					const items = json.items;
					dispatch(receiveSimilarProducts({ id, items }));
				})
				.catch(err => {
					error(err);
					notify.showNetworkError(err);
				});
		};

// @ts-expect-error TS(7031): Binding element 'id' implicitly has an 'any' type.
export const fetchSimilarProducts = ({ id, count, lang, req }) =>
	commonFetchSimilarProducts({
		id,
		count,
		lang,
		req,
	});
