import { notify } from 'Components/common/notify';

import fetch from 'Utils/fetchWrapper';
import { error } from 'Utils/logUtils';
import { getApiCDNBaseUrlV2 } from 'Utils/urlUtils';

import { decrementAPICount } from 'Actions/apiCount';
import { receiveItineraryData, requestItinerary } from 'Actions/itinerary';
import { setAPIServerAPIStatus } from 'Actions/serverStatus';

export const fetchItineraryData =
	({
		id,
		sections = true,
		lang = 'en',
	}: {
		id: number;
		sections?: boolean;
		lang?: string;
	}) =>
	(dispatch: (arg0: any) => void, getState: any) => {
		const state = getState();
		const url = `${getApiCDNBaseUrlV2({
			state,
		})}/api/v6/tour-groups/${id}/experience-itineraries/?&sections=${sections}&language=${lang}`;

		dispatch(requestItinerary({ id }));

		return fetch(url)
			.then(response => response.json())
			.then(json =>
				dispatch(
					receiveItineraryData({
						id,
						response: json,
					}),
				),
			)
			.catch(err => {
				dispatch(setAPIServerAPIStatus(url, err.status));
				error(err);
				notify.showNetworkError(err);
				dispatch(decrementAPICount());
			});
	};

// /api/v6/tour-groups/3436/experience-itineraries/
