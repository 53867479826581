import { notify } from 'Components/common/notify';

import {
	getApiCurrencyParameter,
	getBaseRequestOptions,
} from 'Utils/fetchUtils';
import fetch from 'Utils/fetchWrapper';
import { error } from 'Utils/logUtils';
import { getCurrentCurrency } from 'Utils/stateUtils';
import { getApiCDNBaseUrlV2 } from 'Utils/urlUtils';

import { receiveSlots, requestSlots } from 'Actions/slots';

export const fetchSlots =
	// @ts-expect-error TS(7031): Binding element 'productId' implicitly has an 'any... Remove this comment to see the full error message


		({ id: productId, forDays = 10 }) =>
		(dispatch: any, getState: any) => {
			dispatch(requestSlots(productId));
			const currencyCode = getCurrentCurrency(getState());
			const currencyParam = getApiCurrencyParameter(
				getState(),
				currencyCode,
			);
			const url = `${getApiCDNBaseUrlV2({
				state: getState(),
			})}/api/v6/tour-groups/slots/get/${productId}?for-days=${forDays}${currencyParam}`;
			const options = getBaseRequestOptions(getState());

			return fetch(url, options)
				.then(response => response.json())
				.then(slotsJson => {
					dispatch(receiveSlots(productId, slotsJson));
				})
				.catch(err => {
					notify.showNetworkError(err);
					error(err);
				});
		};
