import { notify } from 'Components/common/notify';

import fetch from 'Utils/fetchWrapper';
import { error } from 'Utils/logUtils';
import { getCurrentLanguageCode } from 'Utils/stateUtils';
import { addQueryParamsToUrl, getApiCDNBaseUrlV2 } from 'Utils/urlUtils';

import { decrementAPICount } from 'Actions/apiCount';
import {
	appendReviewsByCity,
	appendReviewsByCollection,
	receiveReviewsByCity,
	receiveReviewsByCollection,
	receiveReviewsByPersona,
	receiveReviewsByTgid,
	requestReviewsByCity,
	requestReviewsByCollection,
	requestReviewsByPersona,
	requestReviewsByTgid,
} from 'Actions/review';
import { setAPIServerAPIStatus } from 'Actions/serverStatus';
import type {
	TReviewThunkCityParam,
	TReviewThunkCollectionParam,
	TReviewThunkPersonaParam,
	TReviewThunkTGIDParam,
} from 'ReduxTypes/review';

export const fetchReviewsByTgid =
	({
		tgId,
		lang = 'en',
		limit = 20,
		filterType = 'TOP',
	}: TReviewThunkTGIDParam) =>
	(dispatch: any, getState: any) => {
		const state = getState();
		const url = `${getApiCDNBaseUrlV2({
			state,
		})}/api/v2/review/tour-group/id/${tgId}?language=${lang.toUpperCase()}&limit=${limit}`;

		dispatch(requestReviewsByTgid({ tgId, filterType }));

		return fetch(url)
			.then(response => response.json())
			.then(json =>
				dispatch(
					receiveReviewsByTgid({ tgId, reviews: json, filterType }),
				),
			)
			.catch(err => {
				dispatch(setAPIServerAPIStatus(url, err.status));
				error(err);
				notify.showNetworkError(err);
				dispatch(decrementAPICount());
			});
	};

export const fetchReviewsByCollection =
	({
		collectionId,
		secondaryCity,
		limit = 8,
		offset = 0,
		sortOrder = 'DESC',
	}: Omit<TReviewThunkCollectionParam, 'lang'>) =>
	(dispatch: any, getState: any) => {
		const state = getState();
		const lang = getCurrentLanguageCode(state);
		const apiCDNBaseUrl = getApiCDNBaseUrlV2({
			state,
		});
		const urlBase = `${apiCDNBaseUrl}/api/v1/collection/${collectionId}/reviews`;
		const url = addQueryParamsToUrl(urlBase, {
			limit,
			offset,
			sortOrder,
			language: lang.toUpperCase(),
			...(secondaryCity && {
				'secondary-city': secondaryCity,
			}),
			domain: 'HEADOUT',
		})!;

		dispatch(requestReviewsByCollection({ collectionId, sortOrder }));

		return fetch(url)
			.then(response => response.json())
			.then(json => {
				dispatch(
					(offset === 0
						? receiveReviewsByCollection
						: appendReviewsByCollection)({
						collectionId,
						sortOrder,
						reviews: json,
					}),
				);
			})
			.catch(err => {
				error(err);
				notify.showNetworkError(err);
				dispatch(decrementAPICount());
			});
	};

export const fetchReviewsByCity =
	({
		city,
		limit = 8,
		offset = 0,
		sortOrder = 'DESC',
	}: Omit<TReviewThunkCityParam, 'lang'>) =>
	(dispatch: any, getState: any) => {
		const state = getState();
		const lang = getCurrentLanguageCode(state);
		const apiCDNBaseUrl = getApiCDNBaseUrlV2({
			state,
		});
		const urlBase = `${apiCDNBaseUrl}/api/v2/city/${city}/reviews`;
		const url = addQueryParamsToUrl(urlBase, {
			limit,
			offset,
			sortOrder,
			language: lang.toUpperCase(),
		})!;

		dispatch(requestReviewsByCity({ city, sortOrder }));

		return fetch(url)
			.then(response => response.json())
			.then(json => {
				dispatch(
					(offset === 0 ? receiveReviewsByCity : appendReviewsByCity)(
						{
							city,
							sortOrder,
							reviews: json,
						},
					),
				);
			})
			.catch(err => {
				error(err);
				notify.showNetworkError(err);
				dispatch(decrementAPICount());
			});
	};

export const fetchReviewsByPersona =
	({
		personaAffinityId,
		city,
		limit = 8,
		offset = 0,
		sortOrder = 'DESC',
	}: Omit<TReviewThunkPersonaParam, 'lang'>) =>
	(dispatch: any, getState: any) => {
		const state = getState();
		const lang = getCurrentLanguageCode(state);
		const apiCDNBaseUrl = getApiCDNBaseUrlV2({
			state,
		});
		const urlBase = `${apiCDNBaseUrl}/api/v1/persona-affinities/${personaAffinityId}/reviews`;
		const url = addQueryParamsToUrl(urlBase, {
			city,
			limit,
			offset,
			sortOrder,
			language: lang.toUpperCase(),
		})!;

		dispatch(
			requestReviewsByPersona({ city, sortOrder, personaAffinityId }),
		);

		return fetch(url)
			.then(response => response.json())
			.then(json => {
				dispatch(
					receiveReviewsByPersona({
						personaAffinityId,
						city,
						sortOrder,
						reviews: json,
					}),
				);
			})
			.catch(err => {
				error(err);
				notify.showNetworkError(err);
				dispatch(decrementAPICount());
			});
	};
