import { ActionTypes } from 'Actions/actions';
import type {
	TReviewActionCityParam,
	TReviewActionCollectionParam,
	TReviewActionPersonaParam,
	TReviewActionTGIDParam,
} from 'ReduxTypes/review';

export const requestReviewsByTgid = ({
	tgId,
	filterType,
}: Omit<TReviewActionTGIDParam, 'sortOrder'>) => ({
	payload: { tgId, filterType },
	type: ActionTypes.REQUEST_REVIEWS_BY_TGID,
});

export const receiveReviewsByTgid = ({
	tgId,
	filterType,
	reviews,
}: TReviewActionTGIDParam) => ({
	payload: { tgId, filterType, reviews },
	type: ActionTypes.RECEIVE_REVIEWS_BY_TGID,
});

export const requestReviewsByCollection = ({
	collectionId,
	sortOrder,
}: Omit<TReviewActionCollectionParam, 'reviews'>) => ({
	payload: {
		collectionId,
		sortOrder,
	},
	type: ActionTypes.REQUEST_REVIEWS_BY_COLLECTION,
});

export const receiveReviewsByCollection = ({
	collectionId,
	sortOrder,
	reviews,
}: TReviewActionCollectionParam) => ({
	payload: {
		collectionId,
		sortOrder,
		reviews,
	},
	type: ActionTypes.RECEIVE_REVIEWS_BY_COLLECTION,
});

export const appendReviewsByCollection = ({
	collectionId,
	sortOrder,
	reviews,
}: TReviewActionCollectionParam) => ({
	payload: {
		collectionId,
		sortOrder,
		reviews,
	},
	type: ActionTypes.APPEND_REVIEWS_BY_COLLECTION,
});

export const requestReviewsByCity = ({
	city,
	sortOrder,
}: Omit<TReviewActionCityParam, 'reviews'>) => ({
	payload: {
		city,
		sortOrder,
	},
	type: ActionTypes.REQUEST_REVIEWS_BY_CITY,
});

export const receiveReviewsByCity = ({
	city,
	sortOrder,
	reviews,
}: TReviewActionCityParam) => ({
	payload: {
		city,
		sortOrder,
		reviews,
	},
	type: ActionTypes.RECEIVE_REVIEWS_BY_CITY,
});

export const appendReviewsByCity = ({
	city,
	sortOrder,
	reviews,
}: TReviewActionCityParam) => ({
	payload: {
		city,
		sortOrder,
		reviews,
	},
	type: ActionTypes.APPEND_REVIEWS_BY_CITY,
});

export const requestReviewsByPersona = (
	params: Omit<TReviewActionPersonaParam, 'reviews'>,
) => ({
	payload: params,
	type: ActionTypes.REQUEST_REVIEWS_BY_PERSONA,
});

export const receiveReviewsByPersona = (params: TReviewActionPersonaParam) => ({
	payload: params,
	type: ActionTypes.RECEIVE_REVIEWS_BY_PERSONA,
});
