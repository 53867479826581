import {
	EItineraryType,
	Itinerary,
} from 'Containers/common/Itinerary/interface';

export const isItineraryValid = (itinerary: Itinerary) => {
	return !(!itinerary?.active || !itinerary?.sections?.length);
};

export const isHOHOItinerary = (itinerary: Itinerary) => {
	return itinerary?.type === EItineraryType.Hoho;
};

export const isCruisesItinerary = (itinerary: Itinerary) => {
	return itinerary?.type === EItineraryType.Cruise;
};
